<template>
  <div class="information">
    <el-card>
      <div class="information_book">
        <img :src="invationData.bookImage" />
        <div class="invitaiton_item_li_left_flex">
          <div class="invitaiton_item_li_left_flex_p">
            <p>
              <span>关联书籍</span>
              {{ invationData.bookName }}
            </p>
          </div>
          <div class="invitaiton_item_li_left_flex_p curomnt">
            <p>
              <span>券码数量</span>
              <span class="item">{{ invationData.totalNumberCode }}</span>
              <span style="margin-left: 10px">已核销</span>
              <em>{{ invationData.afterNumberCode }}</em>
            </p>
          </div>
          <div class="invitaiton_item_li_left_flex_p">
            <p>
              <span>时效日期</span>
              {{ invationData.endTime }}
            </p>
          </div>
        </div>
      </div>
    </el-card>
    <el-card style="margin-top: 20px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="分配信息" name="first">
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            :header-cell-style="{ background: '#F0F0FF', color: '#252631' }"
          >
            <el-table-column prop="channelName" label="渠道方" align="center">
            </el-table-column>
            <el-table-column prop="number" label="券码数量" align="center">
            </el-table-column>
            <el-table-column prop="logout" label="已核销" align="center">
            </el-table-column>
            <el-table-column
              prop="noCancellation"
              label="未核销"
              align="center"
            >
              <template v-slot="{ row }">
                <span v-if="row.noCancellation * 1 === 0"></span>
                <el-button v-else type="text" @click="notionClick(row)">{{
                  row.noCancellation
                }}</el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop="cancellationRate"
              label="核销率"
              align="center"
            >
              <template v-slot="{ row }">
                <el-progress
                  type="circle"
                  :percentage="
                    row.cancellationRate * 1 > 100
                      ? 100
                      : row.cancellationRate * 1
                  "
                  :width="40"
                ></el-progress>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="核销信息" name="second">
          <el-form :model="form" label-width="100px" inline>
            <el-form-item label="券码号：">
              <el-input
                v-model="form.inviteCode"
                placeholder="请输入"
                @input="getInformationAll"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="来源渠道：">
              <el-select
                v-model="form.channelId"
                @change="getInformationAll"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in channerAllList"
                  :key="index"
                  :label="item.channelName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-table
              :data="informatData"
              stripe
              style="width: 100%"
              class="in_table"
              :header-cell-style="{ background: '#F0F0FF', color: '#252631' }"
            >
              <el-table-column
                prop="headImgUrl"
                label="微信头像"
                align="center"
              >
                <template v-slot="{ row }">
                  <img :src="row.headImgUrl" />
                </template>
              </el-table-column>
              <el-table-column
                prop="nickname"
                label="微信名"
                align="center"
              ></el-table-column>
              <el-table-column prop="sex" label="性别" align="center">
                <template v-slot="{ row }">
                  <span v-if="row.sex === 2">女</span>
                  <span v-else>男</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="school"
                label="单位"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="city"
                label="地区"
                align="center"
              ></el-table-column>
              <el-table-column prop="grade" label="用户属性" align="center">
                <template v-slot="{ row }">
                  <span v-if="row.grade === 'THE_AVERAGE_USER'">普通用户</span>
                  <span v-else-if="row.grade === 'THE_ORGANIZERS'">主办方</span>
                  <span v-else-if="row.grade === 'FIVE_G_SECRETARY_GENERAL'"
                    >会员</span
                  >
                  <span v-else-if="row.grade === 'THE_ADMINISTRATOR'"
                    >管理员</span
                  >
                  <span v-else-if="row.grade === 'SUPER_ADMINISTRATOR'"
                    >超级管理员</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="inviteCode"
                label="券码号"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="channelName"
                label="来源渠道"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="updateTime"
                label="核销时间"
                align="center"
              ></el-table-column>
            </el-table>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-dialog
      title="唯一出版社未核销"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-table
        :data="tanData"
        stripe
        style="width: 100%"
        :header-cell-style="{ background: '#F0F0FF', color: '#151515' }"
      >
        <el-table-column
          prop="inviteCode"
          label="券码号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="endTime"
          label="时效日期"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
        :total="total"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import {
  detailsHead,
  writtenOffGoogleId,
  afterPage,
  writListPage,
  channerAll,
} from "@/api/invitation.js";
const defaultQueryInfo = Object.freeze({
  channelId: "", //渠道商编号
  pageNumber: 0, // 页码
  pageSize: 30, //每页显示个数
  inviteCode: "", //邀请码
  googleId: "", //基本信息编号
});
export default {
  name: "information",
  data() {
    return {
      informationList: [], //核销数据
      form: { ...defaultQueryInfo }, //表单数据
      dialogVisible: false,
      activeName: "first", //默认
      invationData: {},
      tanData: [], //弹窗展示未核销的内容
      tableData: [], //分配信息数据
      pageNumber: 0,
      pageSize: 10,
      total: "",
      channelId: "",
      googleId: "",
      channerAllList: [], //所有渠道商
      informatData: [], // 核销数据
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
  },
  created() {
    this.getById();
    this.getWrittenOff();
    this.getInformationAll();
    this.getChannerAll();
  },
  methods: {
    //获取所有渠道商
    async getChannerAll() {
      const { data: res } = await channerAll();
      if (res.code === 10200) {
        this.channerAllList = res.data;
      }
    },
    //获取核销数据
    async getInformationAll() {
      this.form.googleId = this.id;
      const { data: res } = await writListPage(this.form);
      if (res.code === 10200) {
        this.informatData = res.data.data;
      }
      console.log(res.data.data);
    },
    // 分页
    handleCurrentChange(newPage) {
      this.pageNumber = newPage - 1;
      this.search();
    },
    //点击为核销的
    notionClick(row) {
      this.channelId = row.channelId;
      this.googleId = row.googleId;
      this.search();
    },
    //获取分页数据
    async search() {
      const query = {
        channelId: this.channelId,
        googleId: this.googleId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      const { data: res } = await afterPage(query);
      console.log(res);
      if (res.code === 10200) {
        this.tanData = res.data.data;
        this.total = res.data.pageTotal;
        this.dialogVisible = true;
      }
    },
    //关闭弹窗
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //获取分配信息根据id查
    async getWrittenOff() {
      const { data: res } = await writtenOffGoogleId({ googleId: this.id });
      if (res.code === 10200) {
        this.tableData = res.data;
      }
    },
    //获取头部单个数据
    async getById() {
      const { data: res } = await detailsHead({ id: this.id });
      if (res.code === 10200) {
        this.invationData = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.information {
  overflow: auto;
  width: 100%;
  .information_book {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;

    img {
      width: 94px;
      height: 120px;
      display: block;
      border-radius: 2px;
      margin-right: 20px;
    }

    .invitaiton_item_li_left_flex {
      flex: 1;
      overflow: hidden;

      .invitaiton_item_li_left_flex_p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 12px;

        p {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          font-size: 16px;
          font-weight: 500;
          color: #151515;
        }

        span {
          color: #8d8d8d;
          font-size: 14px;
          font-weight: 400;
          margin-right: 10px;
        }

        .item {
          font-size: 22px;
        }

        em {
          font-size: 22px;
          font-weight: 500;
          color: #4aa9ff;
          font-style: normal;
        }
      }
    }
  }

  /deep/.el-tabs__item.is-active {
    color: #8483d0;
  }

  /deep/ .el-tabs__active-bar {
    background-color: #8483d0;
  }

  /deep/ .el-tabs__item:hover {
    color: #8483d0;
  }
  .in_table {
    margin-top: 20px;
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }
}
</style>