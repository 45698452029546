var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"information"},[_c('el-card',[_c('div',{staticClass:"information_book"},[_c('img',{attrs:{"src":_vm.invationData.bookImage}}),_c('div',{staticClass:"invitaiton_item_li_left_flex"},[_c('div',{staticClass:"invitaiton_item_li_left_flex_p"},[_c('p',[_c('span',[_vm._v("关联书籍")]),_vm._v(" "+_vm._s(_vm.invationData.bookName)+" ")])]),_c('div',{staticClass:"invitaiton_item_li_left_flex_p curomnt"},[_c('p',[_c('span',[_vm._v("券码数量")]),_c('span',{staticClass:"item"},[_vm._v(_vm._s(_vm.invationData.totalNumberCode))]),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v("已核销")]),_c('em',[_vm._v(_vm._s(_vm.invationData.afterNumberCode))])])]),_c('div',{staticClass:"invitaiton_item_li_left_flex_p"},[_c('p',[_c('span',[_vm._v("时效日期")]),_vm._v(" "+_vm._s(_vm.invationData.endTime)+" ")])])])])]),_c('el-card',{staticStyle:{"margin-top":"20px"}},[_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"分配信息","name":"first"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"stripe":"","header-cell-style":{ background: '#F0F0FF', color: '#252631' }}},[_c('el-table-column',{attrs:{"prop":"channelName","label":"渠道方","align":"center"}}),_c('el-table-column',{attrs:{"prop":"number","label":"券码数量","align":"center"}}),_c('el-table-column',{attrs:{"prop":"logout","label":"已核销","align":"center"}}),_c('el-table-column',{attrs:{"prop":"noCancellation","label":"未核销","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.noCancellation * 1 === 0)?_c('span'):_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.notionClick(row)}}},[_vm._v(_vm._s(row.noCancellation))])]}}])}),_c('el-table-column',{attrs:{"prop":"cancellationRate","label":"核销率","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-progress',{attrs:{"type":"circle","percentage":row.cancellationRate * 1 > 100
                    ? 100
                    : row.cancellationRate * 1,"width":40}})]}}])})],1)],1),_c('el-tab-pane',{attrs:{"label":"核销信息","name":"second"}},[_c('el-form',{attrs:{"model":_vm.form,"label-width":"100px","inline":""}},[_c('el-form-item',{attrs:{"label":"券码号："}},[_c('el-input',{attrs:{"placeholder":"请输入","clearable":""},on:{"input":_vm.getInformationAll},model:{value:(_vm.form.inviteCode),callback:function ($$v) {_vm.$set(_vm.form, "inviteCode", $$v)},expression:"form.inviteCode"}})],1),_c('el-form-item',{attrs:{"label":"来源渠道："}},[_c('el-select',{attrs:{"clearable":"","placeholder":"请选择"},on:{"change":_vm.getInformationAll},model:{value:(_vm.form.channelId),callback:function ($$v) {_vm.$set(_vm.form, "channelId", $$v)},expression:"form.channelId"}},_vm._l((_vm.channerAllList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.channelName,"value":item.id}})}),1)],1),_c('el-table',{staticClass:"in_table",staticStyle:{"width":"100%"},attrs:{"data":_vm.informatData,"stripe":"","header-cell-style":{ background: '#F0F0FF', color: '#252631' }}},[_c('el-table-column',{attrs:{"prop":"headImgUrl","label":"微信头像","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('img',{attrs:{"src":row.headImgUrl}})]}}])}),_c('el-table-column',{attrs:{"prop":"nickname","label":"微信名","align":"center"}}),_c('el-table-column',{attrs:{"prop":"sex","label":"性别","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [(row.sex === 2)?_c('span',[_vm._v("女")]):_c('span',[_vm._v("男")])]}}])}),_c('el-table-column',{attrs:{"prop":"school","label":"单位","align":"center"}}),_c('el-table-column',{attrs:{"prop":"city","label":"地区","align":"center"}}),_c('el-table-column',{attrs:{"prop":"grade","label":"用户属性","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [(row.grade === 'THE_AVERAGE_USER')?_c('span',[_vm._v("普通用户")]):(row.grade === 'THE_ORGANIZERS')?_c('span',[_vm._v("主办方")]):(row.grade === 'FIVE_G_SECRETARY_GENERAL')?_c('span',[_vm._v("会员")]):(row.grade === 'THE_ADMINISTRATOR')?_c('span',[_vm._v("管理员")]):(row.grade === 'SUPER_ADMINISTRATOR')?_c('span',[_vm._v("超级管理员")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"inviteCode","label":"券码号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"channelName","label":"来源渠道","align":"center"}}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"核销时间","align":"center"}})],1)],1)],1)],1)],1),_c('el-dialog',{attrs:{"title":"唯一出版社未核销","visible":_vm.dialogVisible,"width":"30%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tanData,"stripe":"","header-cell-style":{ background: '#F0F0FF', color: '#151515' }}},[_c('el-table-column',{attrs:{"prop":"inviteCode","label":"券码号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"endTime","label":"时效日期","align":"center"}})],1),_c('el-pagination',{attrs:{"layout":"prev, pager, next","page-size":_vm.pageSize,"total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }